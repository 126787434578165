export const PRODUCT_SALES = [
  {
    x: "2020-01-01T19:00:00.000Z",
    y: 0,
  },
  {
    x: "2020-01-02T19:00:00.000Z",
    y: 0,
  },
  {
    x: "2020-01-03T19:00:00.000Z",
    y: 3,
  },
  {
    x: "2020-01-04T19:00:00.000Z",
    y: 4,
  },
  {
    x: "2020-01-05T19:00:00.000Z",
    y: 10,
  },
  {
    x: "2020-01-06T19:00:00.000Z",
    y: 1,
  },
  {
    x: "2020-01-07T19:00:00.000Z",
    y: 2,
  },
  {
    x: "2020-01-08T19:00:00.000Z",
    y: 10,
  },
  {
    x: "2020-01-09T19:00:00.000Z",
    y: 5,
  },
  {
    x: "2020-01-10T19:00:00.000Z",
    y: 4,
  },
  {
    x: "2020-01-11T19:00:00.000Z",
    y: 5,
  },
  {
    x: "2020-01-12T19:00:00.000Z",
    y: 5,
  },
  {
    x: "2020-01-13T19:00:00.000Z",
    y: 4,
  },
  {
    x: "2020-01-14T19:00:00.000Z",
    y: 2,
  },
  {
    x: "2020-01-15T19:00:00.000Z",
    y: 0,
  },
  {
    x: "2020-01-16T19:00:00.000Z",
    y: 5,
  },
  {
    x: "2020-01-17T19:00:00.000Z",
    y: 6,
  },
  {
    x: "2020-01-18T19:00:00.000Z",
    y: 10,
  },
  {
    x: "2020-01-19T19:00:00.000Z",
    y: 11,
  },
  {
    x: "2020-01-20T19:00:00.000Z",
    y: 12,
  },

  {
    x: "2020-01-21T19:00:00.000Z",
    y: 15,
  },
  {
    x: "2020-01-22T19:00:00.000Z",
    y: 20,
  },
  {
    x: "2020-01-23T19:00:00.000Z",
    y: 25,
  },
  {
    x: "2020-01-24T19:00:00.000Z",
    y: 19,
  },
  {
    x: "2020-01-25T19:00:00.000Z",
    y: 19,
  },
  {
    x: "2020-01-26T19:00:00.000Z",
    y: 30,
  },
  {
    x: "2020-01-27T19:00:00.000Z",
    y: 40,
  },
  {
    x: "2020-01-28T19:00:00.000Z",
    y: 39,
  },
  {
    x: "2020-01-29T19:00:00.000Z",
    y: 38,
  },
  {
    x: "2020-01-30T19:00:00.000Z",
    y: 37,
  },
  {
    x: "2020-01-31T19:00:00.000Z",
    y: 36,
  },
];
