export const WEBSITE_CATEGORIES = [
  "AI",
  "Art Entertainment",
  "Automotive",
  "Beauty",
  "Blogging",
  "Books",
  "Business",
  "CBD",
  "Construction",
  "Courier",
  "Crypto",
  "Culture & Society",
  "Dental",
  "Digital Marketing",
  "Ecommerce",
  "Education",
  "Electronics",
  "Event Planning",
  "Fashion",
  "Films",
  "Finance",
  "Fitness",
  "Food",
  "Gaming",
  "Gardening",
  "General",
  "Graphic Design",
  "Handyman",
  "Health",
  "History",
  "Home Improvement",
  "Home Services",
  "Hosting",
  "Hotel",
  "Interior Design",
  "Investing",
  "Law",
  "Lifestyle",
  "Marketing",
  "Medical",
  "Mobile Apps",
  "Music",
  "NFT",
  "NGO",
  "News & Media",
  "Nutrition",
  "Parenting",
  "Personal Development",
  "Pets",
  "Photography",
  "Politics",
  "Real Estate",
  "Religion & Spirituality",
  "Restaurant",
  "SEO",
  "Science",
  "Social Media",
  "Software Development",
  "Sports",
  "Tech",
  "Travel",
  "Web Development",
];
