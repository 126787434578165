export const WEBSITES_LIST = [
  {
    key: 1,
    id: "1",
    url: "https://amirarticles.com",
    country: "AF",
    country: { code: "AU", name: "Australia" },

    da: 10,
    dr: 10,
    traffic: 1000,
    links: 3,
    categories: [
      { name: "General", price: 10, cost: 5 },
      { name: "Nutrition", price: 20, cost: 10 },
      { name: "Sports", price: 30, cost: 10 },
      { name: "Crypto", price: 40, cost: 10 },
    ],
    age: "2018-04-19",

    spam: 10,
    extension: ".com",
    vendor: "Umer Hayat",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 2,
    id: "2",
    url: "https://example.pk",
    country: { code: "US", name: "United States" },
    da: 20,
    dr: 20,
    traffic: 5000,
    links: 10,
    categories: [
      { name: "General", price: 50, cost: 10 },
      { name: "Nutrition", price: 40, cost: 10 },
      { name: "Software Development", price: 60, cost: 10 },
    ],
    age: "2018-04-19",
    spam: 20,
    extension: ".pk",
    vendor: "Afaq Khan",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 3,
    id: "3",
    url: "https://newsblog.co.uk",
    country: { code: "AU", name: "Australia" },
    da: 30,
    dr: 30,
    traffic: 2500,
    links: 8,
    categories: [
      { name: "Software Development", price: 25, cost: 10 },
      { name: "General", price: 20, cost: 10 },
      { name: "Sports", price: 30, cost: 10 },
      { name: "Nutrition", price: 25, cost: 10 },
    ],
    age: "2018-04-19",
    spam: 30,
    extension: ".co.uk",
    vendor: "Farooq Ahmed",
    status: false,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 4,
    id: "4",
    url: "https://blogsite.gov",
    country: { code: "PK", name: "Pakistan" },
    da: 40,
    dr: 40,
    traffic: 1500,
    links: 5,
    categories: [
      { name: "General", price: 15, cost: 10 },
      { name: "Sports", price: 20, cost: 10 },
      { name: "Nutrition", price: 18, cost: 10 },
    ],
    age: "2018-04-19",
    spam: 40,
    extension: ".gov",
    vendor: "Umer Hayat",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 5,
    id: "5",
    url: "https://technews.com",
    country: { code: "CN", name: "China" },
    da: 50,
    dr: 50,
    traffic: 8000,
    links: 12,
    categories: [
      { name: "Politics", price: 45, cost: 10 },
      { name: "Health", price: 35, cost: 10 },
      { name: "Nutrition", price: 40, cost: 10 },
    ],
    age: "2018-04-19",
    spam: 50,
    extension: ".com",
    vendor: "Umer Hayat",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 6,
    id: "6",
    url: "https://fashionblog.com",
    country: { code: "JP", name: "Japan" },
    da: 60,
    dr: 60,
    traffic: 2018,
    links: 6,
    categories: [
      { name: "Technology", price: 28, cost: 10 },
      { name: "Interior Design", price: 25, cost: 10 },
      { name: "Nutrition", price: 22, cost: 10 },
    ],
    age: "2018-04-19",

    spam: 60,
    extension: ".com",
    vendor: "Faisal",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 7,
    id: "7",
    url: "https://sportsnews.com",
    country: { code: "INT", name: "Global" },
    da: 70,
    dr: 70,
    traffic: 3500,
    links: 8,
    categories: [
      { name: "Music", price: 32, cost: 10 },
      { name: "Nutrition", price: 30, cost: 10 },
      { name: "Automotive", price: 28, cost: 10 },
    ],
    age: "2018-04-19",
    spam: 70,
    extension: ".com",
    vendor: "Umer Hayat",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 8,
    id: "8",
    url: "https://foodblog.com",
    country: { code: "DE", name: "Germany" },
    da: 80,
    dr: 80,
    traffic: 1800,
    links: 4,
    categories: [
      { name: "Investing", price: 20, cost: 10 },
      { name: "Gaming", price: 18, cost: 10 },
      { name: "Business", price: 15, cost: 10 },
    ],
    age: "2018-04-19",
    spam: 80,
    extension: ".com",
    vendor: "Taimour Baigh",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 9,
    id: "9",
    url: "https://travelblog.com",
    country: { code: "GB", name: "United Kingdom" },
    da: 90,
    dr: 90,
    traffic: 3000,
    links: 9,
    categories: [
      { name: "Travel", price: 35, cost: 10 },
      { name: "Medical", price: 30, cost: 10 },
      { name: "Nutrition", price: 32, cost: 10 },
    ],
    age: "2018-04-19",
    spam: 90,
    extension: ".com",
    vendor: "Hassan Ali",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
  {
    key: 10,
    id: "10",
    url: "https://financeblog.com",
    country: { code: "TW", name: "Taiwan" },
    da: 99,
    dr: 99,
    traffic: 2700,
    links: 6,
    categories: [
      { name: "Lifestyle", price: 30, cost: 10 },
      { name: "Pets", price: 28, cost: 10 },
      { name: "Photography", price: 25, cost: 10 },
    ],
    age: "2018-04-19",

    spam: 99,
    extension: ".com",
    vendor: "Muhammad Usman",
    status: true,
    last_updated: "2023-06-08T06:37:21.424Z",
  },
];
