export const CUSTOMERS_LIST = [
  {
    key: 1,
    id: "1",
    first_name: "John",
    last_name: "Wick",
    email: "johnwick@gmail.com",
    phone: "415 123 1234",
    country: { code: "AU", name: "Australia" },
    last_activity: "2023-06-08T06:37:21.424Z",
    total_orders: 10,
    last_order: "2023-06-08T06:37:21.424Z",
    total_spending: 1000,
    status: true,
  },
  {
    key: 2,
    id: "2",
    first_name: "Alice",
    last_name: "Johnson",
    email: "alicejohnson@gmail.com",
    phone: "416 234 2345",
    country: { code: "US", name: "United States" },
    last_activity: "2023-06-07T10:22:15.123Z",
    total_orders: 5,
    last_order: "2023-06-07T10:22:15.123Z",
    total_spending: 500,
    status: true,
  },
  {
    key: 3,
    id: "3",
    first_name: "David",
    last_name: "Smith",
    email: "davidsmith@gmail.com",
    phone: "417 345 3456",
    country: { code: "GB", name: "United Kingdom" },
    last_activity: "2023-06-06T14:55:03.789Z",
    total_orders: 3,
    last_order: "2023-06-06T14:55:03.789Z",
    total_spending: 300,
    status: false,
  },
  {
    key: 4,
    id: "4",
    first_name: "Emily",
    last_name: "Brown",
    email: "emilybrown@gmail.com",
    phone: "418 456 4567",
    country: { code: "CA", name: "Canada" },
    last_activity: "2023-06-05T18:41:58.512Z",
    total_orders: 8,
    last_order: "2023-06-05T18:41:58.512Z",
    total_spending: 800,
    status: true,
  },
  {
    key: 5,
    id: "5",
    first_name: "Michael",
    last_name: "Taylor",
    email: "michaeltaylor@gmail.com",
    phone: "419 567 5678",
    country: { code: "US", name: "United States" },
    last_activity: "2023-06-04T22:14:39.123Z",
    total_orders: 2,
    last_order: "2023-06-04T22:14:39.123Z",
    total_spending: 200,
    status: true,
  },
  {
    key: 6,
    id: "6",
    first_name: "Olivia",
    last_name: "Martin",
    email: "oliviamartin@gmail.com",
    phone: "420 678 6789",
    country: { code: "AU", name: "Australia" },
    last_activity: "2023-06-03T02:08:10.902Z",
    total_orders: 12,
    last_order: "2023-06-03T02:08:10.902Z",
    total_spending: 1200,
    status: false,
  },
  {
    key: 7,
    id: "7",
    first_name: "Daniel",
    last_name: "Clark",
    email: "danielclark@gmail.com",
    phone: "421 789 7890",
    country: { code: "CA", name: "Canada" },
    last_activity: "2023-06-02T05:52:55.769Z",
    total_orders: 6,
    last_order: "2023-06-02T05:52:55.769Z",
    total_spending: 600,
    status: true,
  },
  {
    key: 8,
    id: "8",
    first_name: "Sophia",
    last_name: "Anderson",
    email: "sophiaanderson@gmail.com",
    phone: "422 890 8901",
    country: { code: "US", name: "United States" },
    last_activity: "2023-06-01T09:29:33.401Z",
    total_orders: 9,
    last_order: "2023-06-01T09:29:33.401Z",
    total_spending: 900,
    status: true,
  },
  {
    key: 9,
    id: "9",
    first_name: "Matthew",
    last_name: "Wilson",
    email: "matthewwilson@gmail.com",
    phone: "423 901 9012",
    country: { code: "GB", name: "United Kingdom" },
    last_activity: "2023-05-31T13:17:18.111Z",
    total_orders: 4,
    last_order: "2023-05-31T13:17:18.111Z",
    total_spending: 400,
    status: false,
  },
  {
    key: 10,
    id: "10",
    first_name: "Emma",
    last_name: "Thompson",
    email: "emmathompson@gmail.com",
    phone: "424 012 0123",
    country: { code: "AU", name: "Australia" },
    last_activity: "2023-05-30T17:02:56.902Z",
    total_orders: 7,
    last_order: "2023-05-30T17:02:56.902Z",
    total_spending: 700,
    status: true,
  },
];
